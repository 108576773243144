<template>
  <Image v-if="rat !== null" v-bind:ratId="rat" v-bind:resize="true"></Image>
</template>
<script>
import Image from "../../components/Rat/Image";
export default {
  components: {Image},
  data: function () {
    return {
      rat: null,
    }
  },
  mounted() {
    this.rat = this.$route.params.id;
  }
}
</script>